import React from "react"
import styled from "styled-components"

function Privacy() {
  return (
    <Container>
      <Wrapper>
        <Title>SMS Notification Opt-In Policy</Title>
        <SubTitle>
          Welcome to Writers Way Solutions LLC! We are delighted to offer you
          the option to receive SMS notifications regarding updates, promotions,
          alerts, and other relevant information about our services. By
          providing your mobile phone number and opting in to receive SMS
          notifications, you agree to the following terms and conditions:
        </SubTitle>
      </Wrapper>
      <Wrapper>
        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            1.
          </span>
          <PrivacyText>
            Consent: By opting in, you grant us permission to send SMS
            notifications to the mobile number provided. These notifications may
            include but are not limited to service updates, promotions, and
            special offers.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            2.
          </span>
          <PrivacyText>
            Frequency: You understand and acknowledge that the frequency of SMS
            notifications may vary. While we strive to ensure these messages are
            informative and valuable, you may receive occasional promotional
            messages.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            3.
          </span>
          <PrivacyText>
            Message and Data Rates: Standard message and data rates may apply.
            These rates are determined by your mobile service provider and are
            your sole responsibility.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            4.
          </span>
          <PrivacyText>
            Opt-Out: You retain the right to opt-out of receiving SMS
            notifications at any time. To unsubscribe, simply reply "STOP" to
            any message received. Once unsubscribed, you will cease to receive
            SMS notifications from Writers Way Solutions LLC.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            5.
          </span>
          <PrivacyText>
            Privacy: We prioritize the privacy of your personal information.
            Your mobile number will only be used for the purpose of sending SMS
            notifications related to our services. We will not share your mobile
            number with third parties for marketing purposes without your
            explicit consent.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            6.
          </span>
          <PrivacyText>
            Security: We are committed to safeguarding your personal
            information. Industry-standard measures are in place to protect your
            mobile number and other personal data from unauthorized access or
            disclosure.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            7.
          </span>
          <PrivacyText>
            Changes to Terms: We reserve the right to modify or update these
            terms and conditions related to SMS notifications. Any changes will
            be effective immediately upon posting the updated terms on our
            website. It is your responsibility to review these terms
            periodically for changes.
          </PrivacyText>
        </div>
      </Wrapper>
      <Wrapper>
        <TextInfo>
          By providing your mobile phone number and opting in to receive SMS
          notifications, you acknowledge that you have read, understood, and
          agreed to abide by these terms and conditions.
        </TextInfo>
        <TextInfo>
          If you have any questions or concerns regarding our SMS notification
          service or these terms and conditions, please do not hesitate to
          contact us at Company phone number <strong>1-888-666-4258</strong> or
          email us at <strong>customersupport@writerswaysolutions.com</strong>.
        </TextInfo>
        <TextInfo>Thank you for choosing Writers Way Solutions LLC!</TextInfo>
      </Wrapper>
    </Container>
  )
}

export default Privacy

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  @media screen and (max-width: 768px) {
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.main1};
`
const SubTitle = styled.h5`
  font-family: ${props => props.theme.fonts.secondary};;
  color: ${props => props.theme.colors.main1};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
`
const PrivacyText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #111;
  font-weight: 400;
  text-align: justify;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const TextInfo = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.theme.colors.dark2};
`
