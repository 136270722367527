import * as React from "react"
import Seo from "../components/seo"
import Privacy from "../components/sections/privacy/Privacy"
import { Helmet } from "react-helmet"

const PrivacyPolicyPage = () => (
  <>
    <Seo title="Contact" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Privacy/>
  </>
)

export default PrivacyPolicyPage
